import React, { useState } from 'react';
import { Cookies } from 'react-cookie';

import styles from './CookiesTable.module.scss';

const CookiesTable = () => {
  const cookies = new Cookies();
  const [hasConsented, setHasConsented] = useState(cookies.get('show-cookie-bar') === 'false');

  const removeConsent = () => {
    const cookies = new Cookies();
    cookies.remove('show-cookie-bar');
    setHasConsented(false);
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.header}>
          <th className={styles.source}>Source</th>
          <th className={styles.name}>Cookie Name</th>
          <th className={styles.purpose}>Purpose</th>
          <th className={styles.info}>More info</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={styles.source}>Need Swap Got</td>
          <td className={styles.name}>show-cookie-bar</td>
          <td className={styles.purpose}>This cookie is used to record if a user has accepted the use of cookies on the Need Swap Got website. By navigating this website, you agree to the cookie policy.</td>
          <td className={styles.info}>Once you have accepted the cookie policy, you will not be prompted again when for 12 months using the same browser.
            { hasConsented && <button className={styles.button} onClick={removeConsent}>Revoke consent</button> }
          </td>
        </tr>
        <tr>
          <td className={styles.source}>Google Analytics</td>
          <td className={styles.name}>_ga<br />_ga*</td>
          <td className={styles.purpose}>These cookies are used to collect information about how visitors use our site. We use the information to compile reports and to help us improve the site. The cookies collect information in an anonymous form, including the number of visitors to the site, where visitors have come to the site from and the pages they visited.</td>
          <td className={styles.info}><a href="http://www.google.com/analytics/learn/privacy.html">Read more about privacy at Google</a></td>
        </tr>
      </tbody>
    </table>
  );
};

export default CookiesTable;
