import React from 'react';

import Layout from '../components/furniture/Layout/Layout';

import generateTitle from '../utils/generateTitle';
import CookiesTable from '../components/furniture/CookiesTable/CookiesTable';

const CookiesPage = ({ location }) => {
  const pageTitle = generateTitle('Cookie Policy');

  return (
    <Layout location={location} pageTitle={pageTitle}>
      <h1>Cookies</h1>
      <p>This site uses cookies. By continuing to browse the site, you are agreeing to our use of cookies. The cookies we use are detailed below:</p>
        <CookiesTable/>
    </Layout>
  );
};

export default CookiesPage;
